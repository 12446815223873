import base from './base';

export const language = {
  ...base,
  [LANG.NL]: {
    ...base[LANG.NL],
    yourChoice: `Je keuze`,
    yourSubscription: `Je abonnement`,
    whatDoYouGet: `Wat krijg je?`,
    understood: 'Ik begrijp het',
    whatDoIGet: 'Wat krijg ik?',
    proceedToCheckout: 'Verder met bestellen',
  },
};
